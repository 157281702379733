if (typeof FastBoot === 'undefined') { (function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.sk = {}));
}(this, (function (exports) { 'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined
      ? window.flatpickr
      : {
          l10ns: {},
      };
  var Slovak = {
      weekdays: {
          shorthand: ["Ned", "Pon", "Ut", "Str", "Štv", "Pia", "Sob"],
          longhand: [
              "Nedeľa",
              "Pondelok",
              "Utorok",
              "Streda",
              "Štvrtok",
              "Piatok",
              "Sobota",
          ],
      },
      months: {
          shorthand: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "Máj",
              "Jún",
              "Júl",
              "Aug",
              "Sep",
              "Okt",
              "Nov",
              "Dec",
          ],
          longhand: [
              "Január",
              "Február",
              "Marec",
              "Apríl",
              "Máj",
              "Jún",
              "Júl",
              "August",
              "September",
              "Október",
              "November",
              "December",
          ],
      },
      firstDayOfWeek: 1,
      rangeSeparator: " do ",
      time_24hr: true,
      ordinal: function () {
          return ".";
      },
  };
  fp.l10ns.sk = Slovak;
  var sk = fp.l10ns;

  exports.Slovak = Slovak;
  exports.default = sk;

  Object.defineProperty(exports, '__esModule', { value: true });

})));
 }